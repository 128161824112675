<template>
  <WeContainer>
    <WeTableSearch v-on:search="onSearch" v-on:clear="onFilterClear">
      <div slot="detail-search">
        <div class="row mb-3">
          <!-- Left -->
          <div class="col-12 col-lg-6">
            <!-- Payment Type -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                v-bind:clearable="true"
                name="payment_type"
                label="Ödeme Yöntemi"
                placeholder="Ödeme Yöntemi"
                v-bind:option-prop="order.paymentTypes"
                v-model="filter.payment_type"
              />
            </div>
            <!-- Payment Type -->

            <!-- Shipping Companies -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                v-bind:clearable="true"
                name="shipping_company"
                label="Kargo Firması"
                placeholder="Kargo Firması"
                v-bind:option-prop="cargo.firmList"
                v-model="filter.shipping_company"
              />
            </div>
            <!-- Shipping Companies -->

            <!-- Order No -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="order_no"
                label="Sipariş No"
                placeholder="Sipariş No"
                v-model="filter.order_no"
              />
            </div>
            <!-- Order No -->

            <!-- Price -->
            <div class="d-block mb-3">
              <div class="form-row align-items-center">
                <div class="col-12 col-lg-3">
                  <label for="price" class="custom-label">Sipariş Tutarı</label>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="row align-items-center">
                    <div class="col">
                      <WePriceInput
                        v-bind:small-size="true"
                        class="mb-0"
                        placeholder="0,00"
                        v-model="filter.start_price"
                        ref="startPrice"
                      />
                    </div>
                    <span>-</span>
                    <div class="col">
                      <WePriceInput
                        v-bind:small-size="true"
                        class="mb-0"
                        placeholder="0,00"
                        v-model="filter.end_price"
                        ref="endPrice"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Price -->

            <!-- Created At -->
            <div class="d-block mb-3">
              <WeRowInput
                form="date"
                size="sm"
                label="Sipariş Tarihi"
                placeholder="Sipariş Tarihi"
                v-bind:date-range="true"
                v-model="filter.date"
              />
            </div>
            <!-- Created At -->

            <!-- Ref No -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="ref_no"
                label="Referans No"
                placeholder="Referans No"
                v-model="filter.ref_no"
              />
            </div>
            <!-- Ref No -->

            <!-- Product Info -->
            <div class="d-block mb-3">
              <div class="form-row align-items-center">
                <div class="col-12 col-lg-3">
                  <label class="custom-label">Ürün Bilgisi</label>
                </div>
                <div class="col-12 col-lg-6">
                  <WeInput
                    class="mb-0"
                    small-size="1"
                    autocomplete="off"
                    v-model="filter.product_search_query"
                  >
                    <template v-slot:prepend>
                      <select
                        class="
                          custom-select custom-select-sm
                          border-right-radius-0
                        "
                        v-model="filter.product_search_type"
                      >
                        <option value="null">Yok</option>
                        <option value="sku">Stok Kodu</option>
                        <option value="name">Ürün Adı</option>
                      </select>
                    </template>
                  </WeInput>
                </div>
              </div>
            </div>
            <!-- Product Info -->
          </div>
          <!-- Left -->

          <!-- Right -->
          <div class="col-12 col-lg-6">
            <!-- Shipping Customer Name -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="shipment_customer_name"
                label="Teslimat - Ad Soyad"
                placeholder="Teslimat - Ad Soyad"
                v-model="filter.shipment_customer_name"
              />
            </div>
            <!-- Shipping Customer Name -->

            <!-- Shipping City -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                v-bind:clearable="true"
                name="shipment_city"
                label="Teslimat - Şehir"
                placeholder="Teslimat - Şehir"
                select-label="name"
                v-bind:option-prop="cityList"
                v-model="filter.shipment_city"
              />
            </div>
            <!-- Shipping City -->

            <!-- Invoice Name -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="invoice_customer_name"
                label="Fatura - Ad Soyad"
                placeholder="Fatura - Ad Soyad"
                v-model="filter.invoice_customer_name"
              />
            </div>
            <!-- Invoice Name -->

            <!-- Shipping City -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                name="invoice_city"
                label="Fatura - Şehir"
                placeholder="Fatura - Şehir"
                v-bind:clearable="true"
                select-label="name"
                v-bind:option-prop="cityList"
                v-model="filter.invoice_city"
              />
            </div>
            <!-- Shipping City -->

            <!-- Customer Email -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="customer_email"
                label="E-Posta Adresi"
                placeholder="E-Posta Adresi"
                v-model="filter.customer_email"
              />
            </div>
            <!-- Customer Email -->

            <!-- Customer GSM -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="phone"
                name="customer_gsm"
                label="Cep Telefonu"
                placeholder="Cep Telefonu"
                v-model="filter.customer_gsm"
              />
            </div>
            <!-- Customer GSM -->
          </div>
          <!-- Right -->
        </div>
      </div>
    </WeTableSearch>
    <WeTable
      v-bind:index="false"
      v-bind:all-select="true"
      v-bind:selectable="true"
      v-bind:select-limit="25"
      v-bind:data="orderList"
      v-bind:allowSortIndex="false"
      v-bind:columns="columns"
      v-bind:actions="tableActions"
      v-bind:ajax="true"
      v-bind:loading="loading"
      v-bind:table-result="tableResult"
      v-bind:exportables="getExportables"
      v-on:on-export="onExport"
      v-on:on-action="onAction"
      v-on:on-checked="onChecked"
      v-on:on-select="onSelect"
      v-on:make-request="updateOrderList"
      ref="orderTable"
    ></WeTable>
    <!-- <div v-else class="position-relative" style="min-height: 200px;">
      <WeLoading />
    </div> -->
    <OrderDetail
      v-show="modal.detail"
      v-bind:order="orderData"
      v-on:close="closeOrderDetail"
      v-on:on-update-cargo="updateCargoBarcodeNumber"
      v-on:on-update-info="updateCargoInfo"
      v-on:on-accept-order="acceptOrder"
      v-bind:erp-config="erpConfig"
      ref="orderDetailRef"
    />
    <WeModal
      v-if="modal.cargo"
      v-on:close="closeCargoModal"
      icon="fas fa-info-circle"
      title="Kargo Bilgileri"
    >
      <template slot="body">
        <div class="row align-items-end">
          <div class="col-2">
            <WeInput
              class="mb-3 mb-lg-0"
              v-model="cargoDetail.package_quantity"
              v-bind:filter-number="true"
              label="Koli Miktarı"
              name="package-quantity"
            />
          </div>
          <div class="col">
            <label for="cargo-id" class="custom-label">Kargo Firması</label>
            <v-select
              id="cargo-id"
              v-model="cargoDetail.cargo"
              v-bind:options="cargo.firmList"
              v-bind:clearable="false"
              label="name"
            >
            </v-select>
          </div>
          <div
            class="col"
            v-if="
              !barcodeSubmit &&
              cargoDetail.cargo &&
              cargoDetail.cargo.is_insurance
            "
          >
            <WeInput
              class="mb-0"
              label="Ürün Tipi"
              v-model="cargoDetail.product_type"
            />
          </div>
          <div class="col">
            <span
              class="btn btn-primary"
              v-if="
                !barcodeSubmit &&
                cargoDetail.cargo &&
                cargoDetail.cargo.is_integration &&
                !cargoDetail.barcode_url
              "
              v-on:click="saveBarcode"
              >Kargo Kaydet</span
            >
            <span v-else-if="barcodeSubmit" class="btn btn-primary"
              ><i class="fas fa-spinner fa-spin"></i
            ></span>

            <WeInput
              v-else-if="!barcodeSubmit"
              class="mb-0"
              v-model="cargoDetail.cargo_tracking_number"
              v-bind:disabled="
                cargoDetail.cargo &&
                cargoDetail.cargo.is_integration &&
                cargoDetail.cargo_tracking_number
              "
              label="Kargo Takip Numarası"
              name="cargo-tracking-number"
            />
          </div>
          <template
            v-if="
              cargoDetail.cargo &&
              cargoDetail.barcode_url
            "
          >
            <div class="col-auto">
              <a
                v-bind:href="cargoDetail.barcode_url"
                target="_blank"
                class="btn btn-outline-secondary"
                ><i class="fas fa-print"></i> Barkod Yazdır</a
              >
            </div>
            <div class="col-auto">
              <span
                v-on:click="updateCargoBarcodeNumber"
                class="btn btn-outline-danger"
                ><i class="fas fa-sync"></i> Barkod Yenile</span
              >
            </div>
          </template>
        </div>
        <div
          class="text-center mt-3"
          v-if="
            !cargoDetail.cargo ||
            (cargoDetail.cargo && !cargoDetail.cargo.is_integration)
          "
        >
          <WeSubmitButton v-on:submit="updateCargoBarcodeNumber" />
        </div>
      </template>
    </WeModal>
    <BulkOrderPrint
      v-bind:data="printOrders"
      v-bind:site-logo="siteLogo"
      ref="bulkPrint"
    />
  </WeContainer>
</template>

<script>
const OrderDetail = () => import("./views/OrderDetail/Index");
const BulkOrderPrint = () => import("./views/BulkOrderPrint/Index");
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "List",
  components: {
    OrderDetail,
    BulkOrderPrint,
  },
  data() {
    return {
      orderData: null,
      loading: false,
      barcodeSubmit: false,
      selectedOrders: [],
      printOrders: [],
      printBtn: null,
      printCounter: 0,
      tableActions: [
        {
          icon: "fas fa-eye",
          class: "btn-sm btn-outline-indigo",
          action: "show-detail",
          tooltip: "Sipariş Detayı",
        },
        {
          icon: "fas fa-print",
          class: "btn-sm btn-outline-secondary ml-2",
          action: "print-out",
          tooltip: "Yazdır",
        },
      ],
      columns: [
        { name: "date", th: "Sipariş Tarihi", type: "datetime" },
        { name: "order_no", th: "Sipariş No", type: "string" },
        { name: "name", th: "Müşteri Adı", type: "string" },
        {
          name: "payment_type",
          th: "Ödeme Yöntemi",
          type: "string",
        },
        {
          name: "bank_result_message",
          th: "Banka Mesajı",
          type: "string",
          maxWidth: "150",
        },
        { name: "ref_no", th: "Referans No", type: "string" },
        { name: "price", th: "Sipariş Tutarı", type: "currency" },
      ],
      copyFilter: {},
      filter: {
        order_status: null, // Sipariş Durumu
        payment_status: null, // Ödeme Durumu
        payment_type: null, // Ödeme Türü
        shipping_company: null, // Kargo Firması
        order_no: null, // Sipariş No
        ref_no: null, // Referans No
        marketplace: null,
        erp_status: null,

        // Müşteri
        shipment_customer_name: null,
        invoice_customer_name: null,
        customer_email: null,
        customer_member_group: null,
        customer_gsm: null,

        // Sipariş Tutarı
        start_price: helper.setPrice(0),
        end_price: helper.setPrice(0),
        price_currency: null,
        product_search_type: null,
        product_search_query: null,

        // Tarih
        date: {
          start: null,
          end: null,
        },
      },
      modal: {
        detail: false,
        cargo: false,
      },
      cargoDetail: {
        order_id: null,
        package_quantity: 1,
        cargo_tracking_number: null,
        cargo: null,
        product_type: null,
      },
      tableResult: {
        item_count: 1,
        current_page: 1,
        total_page: 1,
        total_items: 1,
      },
      erpOptions: [
        {
          id: "1",
          name: "Aktarılan",
        },
        {
          id: "0",
          name: "Aktarılamayan",
        },
        {
          id: "2",
          name: "Gönderilmeyen",
        },
      ],
    };
  },
  methods: {
    ...mapActions("order", [
      "getById",
      "getList",
      "delete",
      "getOrderTypes",
      "getPaymentTypes",
      "updateOrderStatus",
      "setCargoOrder",
      "updateCargoInformation",
      "sendERP",
      "updateIsOk",
    ]),
    ...mapActions({
      getOrderDetail: "orderDetailModal/getById",
    }),
    ...mapMutations({
      clearOrderDetail: "orderDetailModal/clearOrder",
    }),
    ...mapActions("shared", ["getCargoFirm"]),
    ...mapActions("address", ["getCities"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    ...mapMutations("order", ["updateList"]),

    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.row, data.index);
      } else if (data.key == "edit") {
        this.onEdit(data.row);
      } else if (data.key == "show-detail") {
        this.showDetail(data.row.id);
      } else if (data.key == "print-out") {
        this.getOrderById(data.row.id, this.onPrintOut);
      } else if (data.key == "barcode-print") {
        window.open(data.row.cargo_barcode_url, "_blank").focus();
      } else if (data.key == "send-erp") {
        const index = this.orderList.findIndex(
          (item) => item.id === data.row.id
        );
        if (index >= 0) {
          let item = this.orderList[index];
          item.erp_status = "-1";
          this.onSendERP(data.row.id, item);
        }
      }
    },
    acceptOrder(id) {
      this.$swal({
        title: "İşlemi Onaylıyor Musunuz ?",
        text: "Sipariş Başarılı olarak güncellenecek.",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Güncelle",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.updateIsOk({
            order_id: id,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.$toast.success("Sipariş Başarı durumu güncellendi");
              } else {
                this.$toast.error("Sipariş Başarı durumu güncellenemedi");
              }
            },
            onFinish: () => {
              this.closeOrderDetail();
              this.updateOrderList();
            },
          });
        }
      });
    },
    onEdit(row) {
      this.$router.replace("orders/detail/" + row.id);
    },
    onDelete(row) {
      let message = "Siparişi silmek istediğinize emin misiniz ?";

      this.$swal({
        text: message,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.delete({
            id: row.id,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.$swal({
                  title: "Sipariş Başarıyla Silindi",
                  icon: "success",
                  confirmButtonText: "Tamam",
                });
              } else {
                this.$swal({
                  title: "Sipariş Silinemedi",
                  text: result.data.message,
                  icon: "error",
                  confirmButtonText: "Tamam",
                });
              }
            },
          });
        }
      });
    },
    onSelect(data) {
      const checkPermission = permission.check("order", "u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        const selectedValue = data.value;
        const oldValue = data.old;
        const statusIndex = this.orderStatusIndex(selectedValue.id); // Kargo Hazırlanıyor
        if (statusIndex == 4) {
          this.changeToCargoStatus(data);
        } else {
          this.changeOrderStatus(data);
        }
      }
    },
    onChecked(data) {
      if (data && data.length) {
        this.selectedOrders = helper.arrayPluck(data, "id");
      } else {
        this.selectedOrders = [];
      }
    },
    onExport(data) {
      if (data.name == "bulk-print") {
        if (!data.loading) {
          this.printOrders = [];
          this.printCounter = 0;
          this.startBulkPrint(data);
        }
      }
    },
    startBulkPrint(btn) {
      btn.loading = true;
      btn.title = "Hazırlanıyor...";
      this.printBtn = btn;

      if (this.selectedOrders && this.selectedOrders.length) {
        this.selectedOrders.forEach((order) => {
          this.printCounter++;

          this.getById({
            id: order,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                const item = result.data.item;
                this.setOrderDetail(item);
                this.printOrders.push(this.orderData);
              }
            },
            onFinish: () => {
              this.printCounter--;
            },
          });
        });
      }
    },
    closeOrderDetail() {
      this.modal.detail = false;
      this.closeCargoModal();
    },
    changeToCargoStatus(data) {
      this.$swal({
        title: "İşlemi Onaylıyor musunuz ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Kaydet",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const orderId = data.row.id;

          this.modal.cargo = true;
          this.cargoDetail.order_id = orderId;
          this.cargoDetail.data = data; // set data (row id etc.) to cargo detail
          this.getOrderById(orderId, this.submitCargoDetail);
        } else {
          const foundedOrder = this.order.list.find(
            (order) => order.id === data.row.id
          );
          if (foundedOrder) {
            // TODO: Reactivity does not change WeTable actual value
            // helper.copy(data.old, foundedOrder.status);
          }
        }
      });
    },
    changeOrderStatus(data) {
      const request = {
        order: data.row.id,
        status: data.value.id,
      };
      this.updateOrderStatus({
        request,
        onSuccess: (result) => {
          if (result && result.data && result.data.status) {
            this.$toast.info("Sipariş Durumu Güncellendi");
          }
        },
        // onFinish: () => this.updateCargoTrackingNumber(),
      });
    },
    showDetail(id) {
      this.modal.detail = true;
      this.orderData = null;
      this.getOrderById(id);
    },
    getOrderById(id, onFinish = null) {
      this.getById({
        id: id,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.setOrderDetail(result.data.item);
          }
        },
        onFinish: () => {
          if (onFinish) onFinish();
        },
      });
    },
    setOrderDetail(item) {
      const data = {};
      data.id = item.id;
      data.order_no = item.order_no;
      data.order_type = item.order_type ? item.order_type.name : null;
      // data.order_type_index = this.orderStatusIndex(item.order_type_id);
      data.payment_type = item.payment_type ? item.payment_type.name : null;
      data.ref_no = item.ref_no;
      data.bank_company = item.bank_company;
      data.user_ip = item.user_ip ? item.user_ip : null;
      data.user_agent = item.user_agent ? item.user_agent : null;
      data.bank_result = {
        data: item.bank_result !== null ? JSON.parse(item.bank_result) : null,
        bank: item.payment_bank ? item.payment_bank.name : null,
      };
      data.attachment = item.attachment;

      // Invoice Tab
      data.invoice = {
        country: item.invoice_country ? item.invoice_country.name : null,
        city: item.invoice_city ? item.invoice_city.name : null,
        district: item.invoice_district ? item.invoice_district.name : null,
        neighborhood: item.invoice_neighborhood
          ? item.invoice_neighborhood.name
          : null,
        postal_code: item.invoice_postal_code,
        address: item.invoice_address,
      };

      // Shipment Tab
      data.shipment = {
        country: item.shipment_country ? item.shipment_country.name : null,
        city: item.shipment_city ? item.shipment_city.name : null,
        district: item.shipment_district ? item.shipment_district.name : null,
        neighborhood: item.shipment_neighborhood
          ? item.shipment_neighborhood.name
          : null,
        postal_code: item.shipment_postal_code,
        address: item.shipment_address,
      };

      // Customer
      data.customer = {
        id: 1,
        type: 0,
        name: item.invoice_first_name
          ? item.invoice_first_name + " " + item.invoice_last_name
          : "",
        first_name: item.invoice_first_name,
        last_name: item.invoice_last_name,
        email: item.invoice_email,
        phone:
          item.invoice_phone && item.invoice_phone ? item.invoice_phone : null,
        gsm:
          item.invoice_gsm && item.invoice_gsm.length ? item.invoice_gsm : null,
        tcn:
          item.invoice_tcn && item.invoice_tcn.length ? item.invoice_tcn : null,
        vkn:
          item.invoice_vkn && item.invoice_vkn.length ? item.invoice_vkn : null,
        tax_office: item.invoice_tax_office,
        company: item.invoice_company,
      };

      // Cargo
      data.cargo = item.cargo;
      this.cargoDetail.cargo = data.cargo;
      this.cargoDetail.cargo_tracking_number = item.cargo_tracking_number;
      this.cargoDetail.barcode_url = item.cargo_barcode_url;
      data.cargoDetail = this.cargoDetail;

      data.erp = {
        status: item.erp_status,
        message: item.erp_message,
      };

      // Detail Info
      data.gift_wrap = item.gift_wrap;
      data.gift_note = item.gift_note;
      data.coupon_code = item.coupon_code || null;
      data.campaign = {
        code: null,
        price: null,
      };

      // Ürünlerin kopyası
      data.products = helper.clone(item.products);

      const currencyCode = item.currency_code ? item.currency_code.code : "TRY";
      // Kupon İndirimi
      if (item.coupon_discount && parseFloat(item.coupon_discount)) {
        data.coupon_discount = localization.getMoneyFormat(
          currencyCode,
          item.coupon_discount
        );
      }

      // Kampanya İndirimi
      if (item.campaign_discount && parseFloat(item.campaign_discount)) {
        data.campaign_discount = localization.getMoneyFormat(
          currencyCode,
          item.campaign_discount
        );
      }

      // Taksit
      // 0 = Tek Çekim
      data.installment = item.installment;
      data.installment_amount = item.installment_amount;

      // KDV
      data.total_tax = localization.getMoneyFormat(
        currencyCode,
        item.total_tax
      );

      // Kargo
      data.total_cargo = localization.getMoneyFormat(
        currencyCode,
        item.total_cargo
      );

      // Ara Toplam
      data.sub_total = localization.getMoneyFormat(
        currencyCode,
        parseFloat(item.total_price) + parseFloat(item.total_tax)
      );

      // Ürün toplam fiyatı
      data.total_price = localization.getMoneyFormat(
        currencyCode,
        item.total_price
      );

      // Genel Toplam
      data.total_amount = localization.getMoneyFormat(
        currencyCode,
        item.total_amount
      );

      this.orderData = data;
    },
    orderStatusIndex(id) {
      return helper.arrayFind(this.order.orderTypes, "id", id);
    },
    // getOrderTypeName(id) {
    //   const index = this.orderStatusIndex(id);

    //   if (index !== -1) {
    //     return this.order.orderTypes[index].name;
    //   }
    // },
    // getPaymentTypeName(id) {
    //   const index = helper.arrayFind(this.order.paymentTypes, "id", id);

    //   if (index !== -1) {
    //     return this.order.paymentTypes[index].name;
    //   }
    // },
    onSearch() {
      this.copyFilter = helper.clone(this.filter);
      this.$refs.orderTable.currentPage = 1;
      this.updateOrderList();
    },
    getFilter() {
      let copy = this.copyFilter;

      if (!Object.keys(copy).length) {
        return;
      }
      if (copy.start_price && copy.start_price.hasOwnProperty("unmask")) {
        copy.start_price = copy.start_price.unmask || null;
      }
      if (copy.end_price && copy.end_price.hasOwnProperty("unmask")) {
        copy.end_price = copy.end_price.unmask || null;
      }
      copy.date = [];

      if (this.filter.date && this.filter.date.start && this.filter.date.end) {
        Object.entries(this.filter.date).forEach(([key, value]) => {
          if (value) {
            copy.date.push(value);
          }
        });
      }

      // ERP Config
      if (!this.erpConfig) this.filter.erp = null;

      // Marketplace Config
      if (!this.marketplaceConfig) this.filter.marketplace = null;

      // Search Product BY
      let type = this.filter.product_search_type;
      let productQuery = this.filter.product_search_query;
      if (type && type !== "null") {
        if (type == "name") {
          copy.product_name = productQuery;
        } else {
          copy.product_sku = productQuery;
        }
      }

      delete copy.product_search_type;
      delete copy.product_search_query;

      return copy;
    },
    onFilterClear() {
      this.copyFilter = {};
      helper.clearItem(this.filter);

      this.filter.start_price = helper.setPrice(0);
      this.filter.end_price = helper.setPrice(0);

      this.updateOrderList();
    },
    updateOrderList(requestData = null) {
      requestData = requestData || {
        page: 1,
        limit: 25,
      };

      this.loading = true;

      this.getList({
        filter: this.getFilter(),
        filter_start: "is_ok=0",
        table: requestData,
        onSuccess: (result) => {
          helper.copy(result, this.tableResult, ["items", "status"]);
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
    closeCargoModal() {
      this.modal.cargo = false;
      this.cargoDetail = {
        order_id: null,
        package_quantity: 1,
        cargo_tracking_number: null,
        cargo: null,
        barcode_url: null,
      };
    },
    submitCargoDetail() {
      this.changeOrderStatus(this.cargoDetail.data);
    },
    onPrintOut() {
      this.$refs.orderDetailRef.$refs.printButton.click();
    },
    updateCargoBarcodeNumber() {
      if (this.orderData) {
        // Entegrasyon bilgisi | boolean
        const integration =
          this.cargoDetail.cargo && this.cargoDetail.cargo.is_integration;

        // Koli adet, kargo firması
        const requestData = {
          package_quantity: this.cargoDetail.package_quantity || 1,
          cargo_id: this.cargoDetail.cargo ? this.cargoDetail.cargo.id : null,
        };

        // Eğer entegrasyon yoksa kargo takip numarası eklenebilir
        // if (!integration) {
        requestData.cargo_tracking_number =
          this.cargoDetail.cargo_tracking_number;
        // }

        if (this.cargoDetail.cargo.is_insurance) {
          requestData.product_type = this.cargoDetail.product_type;
        }
        if (
          this.cargoDetail.cargo &&
          this.cargoDetail.cargo.id &&
          (this.cargoDetail.cargo_tracking_number ||
            this.cargoDetail.cargo_tracking_number !== "[]")
        ) {
          // Kargo bilgilerini gönder
          this.setCargoOrder({
            id: this.orderData.id,
            form: requestData,
            onSuccess: (result) => {
              if (result.data && result.data.status !== "error") {
                if (integration) {
                  this.cargoDetail.barcode_url = result.data.barcodeUrl;
                  this.cargoDetail.cargo_tracking_number =
                    result.data.trackingNumber;
                }
                this.$toast.success("Kargo kaydı tamamlandı");
              } else {
                if (integration) {
                  this.$toast.error("Kargo kaydı yapılamadı");
                }
              }
            },
            onFinish: () => {
              if (!integration) {
                this.closeCargoModal();
              } else {
                this.barcodeSubmit = false;
              }
            },
          });
        }
      }
    },
    updateCargoInfo(data) {
      if (data.id) {
        if (data.cargoDetail.cargo_tracking_number) {
          const formData = {
            cargo_tracking_number: data.cargoDetail.cargo_tracking_number,
            cargo_id: data.cargo.id,
          };

          this.updateCargoInformation({
            id: data.id,
            form: formData,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.$toast.success("Kargo Bilgisi Güncellendi");
              } else {
                this.$toast.error("Kargo Bilgisi Güncellenemedi");
              }
            },
          });
        } else {
          this.$swal({
            title: "Hatalı İşlem",
            text: "Kargo bilgisini güncellemek için takip numarası girmelisiniz",
            icon: "warning",
          });
        }
      }
    },
    saveBarcode() {
      this.barcodeSubmit = true;
      this.changeOrderStatus(this.cargoDetail.data);
      this.updateCargoBarcodeNumber();
    },
    onSendERP(id, item) {
      if (this.erpConfig && id) {
        this.sendERP({
          order_id: id,
          onSuccess: (result) => {
            if (result && result.data) {
              // Row
              if (item) {
                if (result.data.status) {
                  item.erp_status = true;
                  item.erp_message = result.data.message;
                } else {
                  item.erp_status = false;
                  item.erp_message = result.data.message;
                }
              }

              result.data.status = !result.data.status ? "error" : "success";
              result.data.message =
                result.data.message && result.data.message.length
                  ? result.data.message
                  : "ERP Gönderilemedi";

              this.showAlert(result);
            }
          },
          onError: () => {
            this.$toast.error("ERP Gönderilemedi");
            item.erp_status = false;
            item.erp_message = "ERP Gönderilemedi";
          },
        });
      }
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    getErpButtons(row) {
      let buttons = [];
      if (this.erpConfig) {
        let erpLoading = {
          icon: "fas fa-spinner fa-spin",
          class: "btn-sm btn-outline-primary ml-2",
          action: "sending-erp",
          tooltip: "ERP Gönderiliyor",
        };

        let erpButton = {
          icon: "fas fa-paper-plane",
          class: "btn-sm btn-outline-primary ml-2",
          action: "send-erp",
          tooltip: "ERP Gönder",
        };

        if (row.erp_status !== "-1") {
          if (row.erp_status) {
            erpButton = {
              icon: "fas fa-check",
              class: "btn-sm btn-outline-success cursor-default ml-2",
              action: "erp-sent",
              tooltip: row.erp_message,
            };
          } else if (row.erp_message) {
            erpButton = {
              icon: "fas fa-paper-plane",
              class: "btn-sm btn-outline-danger ml-2",
              action: "send-erp",
              tooltip: row.erp_message + "<br /> Tekrar Gönder",
            };
          }
          buttons = [erpButton];
        } else {
          buttons = [erpLoading];
        }
      }
      const statusName =
        row.status && row.status.name ? row.status.name : row.status;
      switch (statusName) {
        case "Ödeme Bekleniyor":
        case "İptal Edildi":
        case "İade Edildi":
        case "Kısmi İade Edildi":
          buttons = [];
          break;
      }

      return buttons;
    },
    getBankResult(row) {
      let result = row?.bank_message;

      const bankResult = row.bank_result ? JSON.parse(row.bank_result) : null;
      if (bankResult) {
        if (bankResult.hasOwnProperty("ErrMsg")) {
          result = bankResult.ErrMsg;
        }

        if (bankResult.hasOwnProperty("mdErrorMsg")) {
          result = bankResult.mdErrorMsg;
        }
      }

      return result;
    },
    setLastVisitTime() {
      const now = new Date().toLocaleString("tr-TR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        hour12: false,
        minute: "2-digit",
      });
      localStorage.setItem("lastFailedOrderVisit", now);
    },
  },
  computed: {
    ...mapState({
      order: (state) => state.order,
      shared: (state) => state.shared,
      cargo: (state) => state.shared.cargoCompanies,
      memberGroups: (state) => state.membergroup.list,
      config: (state) => state.session.config,
      marketplace: (state) => state.marketplace,
      cityList: (state) => state.address.cities,
      orderDetailModal: (state) => state.orderDetailModal,
    }),
    siteLogo() {
      return this.config["site.logo"] || null;
    },
    // getColumns() {
    //   this.columns = this.columns.filter((item) => item.name !== "status");

    //   const orderTypes = this.order.orderTypes;
    //   const data = {
    //     name: "status",
    //     th: "Sipariş Durumu",
    //     type: "select",
    //     options: orderTypes,
    //     width: "10%",
    //   };

    //   this.columns.splice(5, 0, data);

    //   return this.columns;
    // },
    orderList() {
      return this.order.list.map((row) => {
        // ERP Status
        row.buttons = this.getErpButtons(row);
        row.bank_result_message = this.getBankResult(row);

        return row;
      });
    },
    erpConfig() {
      return (
        this.config["site.erp_integration"] == "1" ||
        this.config["site.erp_integration"] == 1
      );
    },
    marketplaceConfig() {
      return (
        this.config["site.marketplace_integration"] == "1" ||
        this.config["site.marketplace_integration"] == 1
      );
    },
    getExportables() {
      let result;

      if (this.selectedOrders && this.selectedOrders.length) {
        // result = ["bulk-print", "excel", "pdf"];
        result = ["bulk-print"];
      } else {
        result = [];
      }

      return result;
    },
    detailMarketplaceList() {
      let marketplaceList = this.marketplace.list;
      let eCommerce = {
        id: "e-commerce",
        title: "Pazaryerinde Olmayanlar",
      };

      const result = [eCommerce, ...marketplaceList];
      return result;
    },
  },
  mounted() {
    if (this.marketplaceConfig) {
      this.$store.dispatch("marketplace/getList");
    }
    this.setLastVisitTime();
    this.getCargoFirm();
    this.getPaymentTypes();
    this.getOrderTypes();
    this.getCities({
      id: "G85jd70Qz",
    });
  },
  watch: {
    printCounter: function (currentValue) {
      if (currentValue <= 0) {
        this.$refs.bulkPrint.$refs.bulkPrintButton.click();
        this.printBtn.loading = false;
        this.printBtn.title = "Seçilenleri Yazdır";
      }
    },
  },
};
</script>
